@import "~utils/vars";

.button {
  background-color: $primaryColor;
  color: white;
  // height: 35px;
  padding-inline: 0.7em;
  border-radius: 5px;
  white-space: nowrap;
  font-family: "Manrope", sans-serif !important;
}

.button[disabled] {
  background-color: rgba(1, 1, 1, 0.2);
  color: #fcfcfc;
  pointer-events: none;
  cursor: not-allowed;
  user-select: none;
  border: none !important;
  * {
    user-select: none;
  }
}
.border-button {
  border: 2px solid rgb(0, 184, 0);
  background-color: transparent;
  color: rgb(0, 184, 0);
  font-weight: bold;
}
.warning-button {
  background-color: rgba(222, 118, 0, 0.922);
}
.icon-button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;

  .icon {
    display: flex;
  }
}
