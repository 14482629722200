@import "~utils/vars";

.wrapper.fullPage {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - #{$navbarHeight});
}

.wrapper.inline {
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
}
