.wrapper {
  padding-inline: 1em;

  section {
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
      color: #1176ae;
      margin-bottom: 0.3em;
      font-size: 1.4rem;
      text-align: center;
    }

    p {
      color: #555555;
      margin-bottom: 3em;
      text-align: center;
    }

    img {
      width: 60%;
    }
  }

  section.insts {
    margin-top: 8em;
    img {
      width: 70%;
    }
  }

  section.companies {
    margin-bottom: 0em;
  }

  .banner {
    display: flex;
    justify-content: center;
    margin-bottom: 5em;

    img {
      width: 60%;
    }
  }

  .footer {
    color: #1176ae;
    font-weight: 600;
    text-align: center;
    margin-bottom: 3em;
    margin-top: 2em;
  }
}
