@import "~utils/vars";

.dark {
  .top {
    span {
      color: #555555;
    }
  }
}
.light {
  .top {
    span {
      svg {
        color: #ffffff !important;
      }
    }
  }
}

.wrapper {
  display: flex;
  align-items: center;
  // position: relative;

  .top {
    display: flex;
    align-items: center;
    justify-content: space;
    // min-width: 210px;
    cursor: pointer;
    color: #fff;
    .image {
      width: 30px;
      height: 30px;
      border-radius: 100%;
      margin-right: 10px;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        margin: 0;
        color: rgba(1, 1, 1, 0.4);
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 100%;
      }
    }
    .name {
      display: flex;
      align-items: center;

      svg {
        color: white;
        margin-top: 2px;
      }
    }
  }

  .dropdownWrapper {
    position: absolute;
    top: 50px;
    right: 0;
    width: 100%;
    border-radius: 5px;
    background-color: #ffffff;
    max-width: 300px;
    box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
    max-width: 210px;

    .menu {
      display: flex;
      align-items: center;
      width: 100%;
      border-radius: 5px;
      border-bottom: 1px solid rgba(151, 151, 151, 0.15);
      cursor: pointer;
      padding: 1px 5px;
      background: none;

      svg {
        width: 20px;
        color: #30475e;
        margin-right: 15px;
      }
      p {
        font-size: 14px;
        color: #30475e;
        white-space: nowrap;
      }

      &:last-child {
        margin-bottom: 0;
      }
      &:hover {
        background: #eeeeee;
      }

      &:disabled {
        opacity: 0.3;
        cursor: not-allowed;
        &:hover {
          background: none;
        }
      }
    }
    .accessDate {
      background-color: rgba(0, 0, 0, 0.063);
      span {
        font-size: 0.9rem;
        font-weight: 500;
        color: $errorTextColor;
        display: block;
      }
    }
  }
  svg {
    stroke-width: 2px;
    margin-left: 5px;
  }
}

@media (max-width: 800px) {
  .mobileNavigation {
    .nav {
      margin-bottom: 40px;
      &:first-child {
        margin-top: 50px;
      }
      p {
        transition: all 0.5s;
        opacity: 0;
        font-size: 18px;
        font-weight: 500;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          width: 20px;
          margin-left: 5px;
        }
      }
    }
  }
}
