@import "~utils/vars";

// Dark Navbar changes . Default style is for light navbar
.wrapper.dark {
  background-color: #1176ae;

  .logo {
    color: white !important;
  }

  box-shadow: none !important;
  .navigationWrapper.navigationWrapper.desktopNavigation {
    .nav {
      color: white !important;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .indicator {
        width: 0;
        height: 2px;
        transition: all 0.2s;
      }
      .activeRoute {
        width: 100%;
        height: 2px;
        background-color: #1176ae;
      }
    }

    .nav:hover {
      border-bottom: 1px solid white;
    }

    .nav.profileContainer {
      border-bottom: none;
    }
  }
  .mobileNavigation .dropdown {
    background-color: #1176ae !important;
    box-shadow: none !important;
    .nav {
      color: white !important;
    }
  }
}

//  Exclusive to the dark navbar ----------------------------------
.transparent {
  background-color: transparent !important;
}

button.login {
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 5px;
  background-color: #ffffff;
  width: 125px;
  height: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #1176ae;
  svg {
    width: 20px;
    stroke: #1176ae;
    margin-right: 5px;
  }
}

button.invertButton {
  border: 1px solid #1176ae;
}

// Common and NavbarLight priority style
// Dark Navbar style differences written on top
.wrapper {
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  width: 100%;
  height: $navbarHeight;
  z-index: 11;
  background-color: #ffffff;
  box-shadow: 0 4px 4px 0 rgba(1, 1, 1, 0.1);

  .container {
    position: relative;
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .logo {
      color: #000000;

      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 48px;
        margin-right: 10px;
      }

      div {
        display: inline-flex;
        flex-direction: column;

        span:first-child {
          font-size: 0.8rem;
        }

        span:last-child {
          font-weight: 400;
          font-size: 0.7rem;
        }
      }
    }
    .hamburger,
    .mobileNavigation {
      display: none;
    }
    .navigationWrapper.desktopNavigation {
      display: flex;
      .nav {
        display: flex;
        align-items: center;
        cursor: pointer;
        margin-right: 20px;
        color: #555555;

        height: 2em;
      }
    }
    .navigationWrapper.mobileNavigation {
      .dropdown.closed {
        height: 0;
        opacity: 0;
        .nav {
          opacity: 0 !important;
        }
      }
      .dropdown.open {
        opacity: 1;
        .nav {
          opacity: 1 !important;
        }
      }
      .dropdown {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: absolute;
        top: $navbarHeight;
        left: 50%;
        transform: translate(-50%, 0);
        width: 100vw;
        overflow: hidden;
        color: #2f2f2f;
        background-color: #ffffff;
        transition: all 0.3s;
        .hamburger {
          cursor: pointer;
          margin-right: 10px;
        }
        .nav {
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          height: 40px;
          margin-bottom: 20px;
          width: 100%;
          color: #2f2f2f;
          &:last-child {
            margin-bottom: 30px;
          }
          &:first-child {
            margin-top: 30px;
          }
          p {
            transition: all 0.5s;
            font-size: 18px;
            font-weight: 500;
            display: flex;
            align-items: center;
            justify-content: center;
            svg {
              width: 20px;
              margin-left: 5px;
            }
          }
        }
      }
    }
  }
}

.invertColor {
  color: #1176ae;
}
@media (max-width: 1000px) {
  .wrapper {
    .container {
      .navigationWrapper.desktopNavigation {
        display: none;
      }
      .navigationWrapper.mobileNavigation {
        display: flex;
        .hamburger {
          display: flex;
          align-items: center;
        }
      }
    }
  }
}
